/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */<template>
  <div class="testimonial-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Meta List</h3>
      <div class="widget">
        <div class="testimonial-content">
          <b-form
            v-promise-btn="{ action: 'submit' }"
            @submit.prevent="updateMetaData"
            @reset.prevent="resetForm()"
          >
            <div>
              <div class="font-18 py-3">
                Information
                <!-- <span class="font-12">(* Required information)</span> -->
              </div>
              <div>
                <b-form-group>
                  <label>Name</label> <b-input v-model="data.name" readonly></b-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label>Title</label> <b-input v-model="data.title"></b-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label>keyword</label> <b-input v-model="data.keyword"></b-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label>Page Type</label> <b-input v-model="data.pageType" readonly></b-input>
                </b-form-group>
              </div>
              <div class="pt-3">
                <b-form-group>
                  <label>Description</label> <br />
                  <b-form-textarea
                    v-model="data.description"
                    id="textarea"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>
            <div v-if="showError" class="error-text pt-2 text-center">{{ message }}</div>
            <div class="d-flex justify-content-center pt-3">
              <div><b-button variant="success" type="submit">Update</b-button></div>
              <div><b-button variant="danger" type="reset" class="ml-5">Cancel</b-button></div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div></template
>
<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: { headerCustom },
  data() {
    return {
      data: { name: "", title: "", description: "", keyword: "", pageType: "" },
      showError: false,
      message: "",
      Id: ""
    };
  },
  created() {
    this.Id = this.$route.params.id;
    if (this.Id) {
      this.getOneMetaData();
    }
  },
  methods: {
    getOneMetaData() {
      service.getOneMetaData(this.Id, result => {
        if (result.status == 200) {
          this.data = result.data;
        }
      });
    },
    resetForm() {
      this.$router.push({ name: "viewMetaDataList" });
    },
    updateMetaData() {
      service.updateMetaData(this.data, this.Id, resultData => {
        if (resultData.status == 200) {
          this.$toaster.success("Meta Data updated");
          this.$router.push({ name: "viewMetaDataList" });
        } else {
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.testimonial-section {
  .testimonial-content {
    padding: 20px 20px;
  }
}
.red {
  color: red;
}</style
><style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
